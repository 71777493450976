<template lang="pug">
	div.page-monitor
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Monitor
			.widget-body

				.ov-table-wrapper
					table.table.table-bordered.table-sm.table-hover.table-responsive-sm
						thead
							tr
								th Status
								th ClicksNodeID
								th IP:PORT
								th Name
								th &nbsp;
						tbody
							tr(v-if="records.length === 0")
								td(colspan="5") No matching records were found
							tr(v-for="r in records", :key="r.id")
								td
									span.badge.badge-pill.badge-danger(v-if="r.status==0") Offline
									span.badge.badge-pill.badge-success(v-if="r.status==1") Online
								td {{ r.node_id }}
								td {{ r.ip }}:{{ r.port }}
								td {{ r.name }}
								td.actions.actions-sm
									a.btn.btn-sm.btn-secondary(href="")
										i.la.la-trash
							tr
								td &nbsp;
								td &nbsp;
								td
									input.form-control.form-control-sm(type="text", v-model="newRecord.ip")
								td
									input.form-control.form-control-sm(type="text", v-model="newRecord.name")
								td.actions
									button.btn.btn-sm.btn-primary(type="button", @click="saveNewRecord()")
										i.la.la-file
</template>
<script>
export default {
  name: 'Bidders',
  data() {
    return {
      newRecord: {
        ip: '',
        name: '',
      },
      busy: false,
      records: [],
    };
  },
  methods: {
    async saveNewRecord() {
      this.busy = true;
      let data = {
        ip: this.newRecord.ip,
        name: this.newRecord.name,
        type: 'clicks',
      };
      try {
        await this.$ovReq.post('server/save', data);
      } catch (e) {
        console.error(e);
      }
      this.newRecord.ip = '';
      this.newRecord.name = '';
      this.busy = false;
      this.loadData();
    },
    async loadData() {
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('server/getList');
        this.records = resp.records;
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
